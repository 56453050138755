<template>
  <div><iframe :src="`${this.apiUrl}/portal/logout`" style="width: 0;height: 0;border: 0" id="logoutFrame"/></div>
</template>

<script>
// import store from '@/plugins/store'
import sec from "@/plugins/user";

export default {
  mounted() {
    const that = this
    const iframe = document.querySelector('#logoutFrame')
    iframe.onload = function (){
      sec.removeUser()
      that.$router.push("/login")
    }
  }
}
</script>
